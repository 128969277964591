import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3572675178/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Brands`}</h1>
    <p>{`After creating an account and getting access to the dashboard, the next step is to create a test `}<strong parentName="p">{`Brand`}</strong>{` (Merchant). This lets you create locations and add them to a Program so you can start tracking transactions.`}</p>
    <p>{`The `}<strong parentName="p">{`Brand`}</strong>{` object is used to aggregate locations and keep track of the Brand consent. To track real-time transactions at a Brand's locations, you need to obtain consent from authorised personnel (Brand Contact), and provide the Brand with access to view transactional data. You can do this when creating the Brand (see below).`}</p>
    <p>{`Once you create a `}<strong parentName="p">{`Brand`}</strong>{`, it cannot be deleted.`}</p>
    <h2>{`Create Brand`}</h2>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/gifs/create-brand.gif",
        "alt": "Create brand",
        "title": "Create brand"
      }}></img></p>
    <p>{`To add a new Brand, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/brands"
      }}>{`Brands`}</a>{` page on the Fidel Dashboard, click the `}<strong parentName="p">{`Add a brand`}</strong>{` button and enter a name. If you can't find the brand in the Fidel Dashboard, you can create a new one. Optionally, you can add a link to the brand website and logo (Note: the links cannot be added later using the Dashboard). You can also `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-brand"
      }}>{`create a Brand`}</a>{` with the API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/brands \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
    "name": "Brand B",
    "logoURL": "https://example.com/logo.png",
    "websiteURL": "https://example.com/"
  }'
`}</code></pre>
    <h2>{`Brand Consent`}</h2>
    <blockquote>
      <p parentName="blockquote">{`NOTE: Brand consent is only required in the Live environment. Test Brands are automatically given Brand consent.`}</p>
    </blockquote>
    <p>{`All brands must be approved by the authorised Brand Contact. There are two ways that brands are approved. For an external brand, you may request Brand consent either in the dashboard, or with our API. For self-funded Brands, this can be skipped by auto-approving consent.`}</p>
    <h3>{`Requesting Brand Consent`}</h3>
    <p>{`On the dashboard, enter the name and email of the Brand Contact and Fidel will send an email inviting them to create a Brand account at `}<a parentName="p" {...{
        "href": "https://clo.fidel.uk"
      }}>{`clo.fidel.uk`}</a>{`. To request Brand Consent with the API, use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-brand-user"
      }}>{`Brand Consent Endpoint`}</a>{`. After creating an account, the Brand User provides consent and will have access to transactions made by cardholders at participating locations.`}</p>
    <div className="info-box">
Brand Consent is only required in the Live environment. Test Brands are automatically given Brand consent.
    </div>
    <p>{`You can monitor consent status on the dashboard and also set up a `}<inlineCode parentName="p">{`brand.consent`}</inlineCode>{` webhook to be notified when the status changes.`}</p>
    <h3>{`Auto-Approve Consent`}</h3>
    <p>{`If you're funding your own offers, or you've received direct authorisation from a brand, you can auto-approve the brand's consent:`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/autoapproveConsent.png",
        "alt": "Auto approve Brand",
        "title": "auto-approve brand"
      }}></img></p>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-brand-user"
      }}>{`Create Brand User`}</a>{` endpoint in the Brand Consent API, you can auto-approve your Brand by adding `}<inlineCode parentName="p">{`skipInvite: true`}</inlineCode>{` to the JSON request with the brand owner information. Keep in mind this endpoint only works with your Live API Key, the one that starts with `}<inlineCode parentName="p">{`sk_live_`}</inlineCode>{`. You'll also need to have a Brand and Program created with the same live API key, before you can use the endpoint. Here's a cURL example, don't forget to replace `}<inlineCode parentName="p">{`{brandId}`}</inlineCode>{` and `}<inlineCode parentName="p">{`{programId}`}</inlineCode>{` before running it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
https://api.fidel.uk/v1/brands/{brandId}/programs/{programId}/users \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
    "email": "email@fidel.uk",
    "nameFirst": "Test",
    "nameLast": "User",
    "title": "Docs",
    "skipInvite": true
}'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      